<template>
  <v-app>
    <v-app-bar
      app
    >
      <v-spacer></v-spacer>
      <div><h1>Pupíčk-o-meter</h1></div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container v-if="last_reading">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-center justify-center">
                <h2> Air quality</h2>
              </v-card-title>
              <v-card-subtitle>
                {{timestamp}}
              </v-card-subtitle>
              <v-card-text>
                <v-progress-linear striped height="30" value="100" :color="colorAqi" dark>
                  <template><h2>{{aqi}}</h2></template>
                </v-progress-linear>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title><h2>PM 2.5</h2></v-card-title>
              <v-card-subtitle>
                {{pm2_5_level}}
              </v-card-subtitle>
              <v-card-text class="justify-center text-center">
                <VueSvgGauge
                  :start-angle="-110"
                  :end-angle="110"
                  :value="pm2_5"
                  :min="0"
                  :max="100"
                  :separator-step="0"
                  :gauge-color="colorPm2_5"
                >
                  <div class="inner-text">
                    <h1>{{pm2_5}}</h1>
                  </div>
                </VueSvgGauge>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title><h2>PM 10</h2></v-card-title>
              <v-card-subtitle>
                {{pm10_level}}
              </v-card-subtitle>
              <v-card-text class="justify-center text-center">
                <VueSvgGauge
                  :start-angle="-110"
                  :end-angle="110"
                  :value="pm10"
                  :min="0"
                  :max="150"
                  :separator-step="0"
                  :gauge-color="colorPm10"
                >
                  <div class="inner-text">
                    <h1>{{pm10}}</h1>
                  </div>
                </VueSvgGauge>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title><h2>PM 1</h2></v-card-title>
              <v-card-subtitle>
                {{pm1_level}}
              </v-card-subtitle>
              <v-card-text class="justify-center text-center">
                <VueSvgGauge
                  :start-angle="-110"
                  :end-angle="110"
                  :value="pm1"
                  :min="0"
                  :max="150"
                  :separator-step="0"
                  :gauge-color="colorPm1"
                >
                  <div class="inner-text">
                    <h1>{{pm1}}</h1>
                  </div>
                </VueSvgGauge>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-text>
                <div class="justify-center text-center"><h1><v-icon x-large :color="tempColor">mdi-thermometer</v-icon>{{temperature}} °C</h1></div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-text>
                <div class="justify-center text-center"><h1><v-icon x-large>mdi-gauge</v-icon>{{pressure}} hPa</h1></div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-text>
                <div class="justify-center text-center"><h1><v-icon x-large color="green">mdi-battery-70</v-icon>{{voltage}} V</h1></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-else>
        <v-row>
          <v-col cols="12" class="justify-center text-center">
            <v-progress-circular indeterminate color="primary">
            
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { VueSvgGauge } from 'vue-svg-gauge'
import { CosmosClient } from "@azure/cosmos";
import { DateTime } from "luxon";

const CONNECTION_STRING = "AccountEndpoint=https://pupick-o-meter-db.documents.azure.com:443/;AccountKey=ykBB6QoUYVCd10Xu24p4oinwqqrtrBRwPXpypuZ2dwd94q1j1WLSW2ZrLASTYCFkgerTINedT5fLACDbkfmwMA==;";

const LAST_UPDATE_QUERY = {query: "SELECT * from c ORDER BY c.timestamp DESC OFFSET 0 LIMIT 1", parameters: []}

export default {
  name: 'App',

  components: {
    VueSvgGauge
  },

  data: () => ({
    last_reading: null
  }),
  mounted() {
    this.refresh_data()
  },

  computed: {
    good: function() {
      return "#50f0e6";
    },
    fair: function() {
      return "#50ccaa";
    },
    moderate: function() {
      return "#f0e641";
    },
    poor: function() {
      return "#ff5050";
    },
    very_poor: function() {
      return "#960032";
    },
    extremely_poor: function() {
      return "#7d2181";
    },
    timestamp() {
      return DateTime.fromISO(this.last_reading.timestamp).toLocaleString(DateTime.DATETIME_MED);
    },
    pressure() {
      return this.last_reading.readings.pressure;
    },
    pm1() {
      return this.last_reading.readings.pm1;
    },
    pm2_5() {
      return this.last_reading.readings.pm2_5;
    },
    noise() {
      return this.last_reading.readings.noise;
    },
    humidity() {
      return this.last_reading.readings.humidity;
    },
    temperature() {
      return this.last_reading.readings.temperature;
    },
    pm10() {
      return this.last_reading.readings.pm10;
    },
    voltage() {
      return this.last_reading.readings.voltage;
    },
    aqi() {
      if (this.pm2_5 < 10 && this.pm10 < 20)
        return "Good";
      else if (this.pm2_5 < 20 && this.pm10 < 40)
        return "Fair";
      else if (this.pm2_5 < 25 && this.pm10 < 50)
        return "Moderate";
      else if (this.pm2_5 < 50 && this.pm10 < 100)
        return "Poor";
      else if (this.pm2_5 < 75 && this.pm10 < 150)
        return "Very poor";
      else
        return "Extremely poor";
    },
    colorAqi() {
      return this[this.aqi.toLowerCase().replace(" ", "_")];
    },
    pm2_5_level() {
      if (this.pm2_5 < 10)
        return "Good";
      else if (this.pm2_5 < 20)
        return "Fair";
      else if (this.pm2_5 < 25)
        return "Moderate";
      else if (this.pm2_5 < 50)
        return "Poor";
      else if (this.pm2_5 < 75)
        return "Very poor";
      else
        return "Extremely poor";
    },
    colorPm2_5() {
      return this[this.pm2_5_level.toLowerCase().replace(" ", "_")];
    },
    pm10_level() {
      if (this.pm10 < 20)
        return "Good";
      else if (this.pm10 < 40)
        return "Fair";
      else if (this.pm10 < 50)
        return "Moderate";
      else if (this.pm10 < 100)
        return "Poor";
      else if (this.pm10 < 150)
        return "Very poor";
      else
        return "Extremely poor";
    },
    colorPm10() {
      return this[this.pm10_level.toLowerCase().replace(" ", "_")];
    },
    pm1_level() {
      if (this.pm1 < 10)
        return "Good";
      else if (this.pm1 < 20)
        return "Fair";
      else if (this.pm1 < 25)
        return "Moderate";
      else if (this.pm1 < 50)
        return "Poor";
      else if (this.pm1 < 75)
        return "Very poor";
      else
        return "Extremely poor";
    },
    colorPm1() {
      return this[this.pm1_level.toLowerCase().replace(" ", "_")];
    },
    tempColor() {
      if (this.temperature < 10)
        return "blue";
      else if (this.temperature < 25)
        return "orange";
      else
        return "red";
    }

  },
  methods: {
    refresh_data(){
      this.cosmosClient = new CosmosClient(CONNECTION_STRING);
      this.db = this.cosmosClient.database("pupick-o-meter");
      this.container = this.db.container("metrics");
      this.container.items.query(LAST_UPDATE_QUERY).fetchAll().then((r) => this.last_reading = r.resources[0]);
    }
  }
};
</script>


<style scoped lang="stylus">
.inner-text {
  position: relative;
  top: 70%;
  transform: translateY(-50%);
}
</style>